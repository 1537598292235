// noinspection JSUnresolvedVariable

/**
 * @file
 * Theme Helpers Scripts.
 */

// Requires.
require('./helpers/_functions.js');
require('./helpers/_init.js');
