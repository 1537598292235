// noinspection JSUndeclaredVariable

/**
 * @file
 * Functions declaration.
 */

// Functions declaration.
(function ($, window, document, undefined) {
  "use strict";

  /**
   * Cc Helper - Perform an Ajax call with options and return a Promise.
   *
   * @type {jQuery.Function}
   *
   * @param {string} url
   *   The URL to send the Ajax request to.
   * @param {Object} options
   *   An object containing Ajax options.
   *
   * @returns {Promise}
   *   A Promise that resolves with the Ajax response or rejects with an error.
   */
  $.ccHelperAjaxCall = function (url, options) {
    // Return.
    return new Promise(function (resolve, reject) {
      let data = options.data;
      let dataCallback = $[data];
      // If data is a string that corresponds to a callback function.
      if (typeof data === 'string' && typeof dataCallback === 'function') {
        // Execute that function to get the data.
        data = dataCallback();
      }
      // Perform the call.
      $.ajax({
        url: url,
        method: options.method,
        data: data,
        dataType: options.dataType,
        async: options.async,
        cache: options.cache,
        timeout: options.timeout,
        beforeSend: function (jqXHR, settings) {
          let callbackBeforeSend = $[options.callbackBeforeSend];
          if (typeof callbackBeforeSend === 'function') {
            callbackBeforeSend(jqXHR, settings);
          }
        },
        success: function (response, textStatus, jqXHR) {
          let callbackOnSuccess = $[options.callbackOnSuccess];
          if (typeof callbackOnSuccess === 'function') {
            callbackOnSuccess(response, textStatus, jqXHR);
          }
          resolve(response);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          let callbackOnError = $[options.callbackOnError];
          if (typeof callbackOnError === 'function') {
            callbackOnError(jqXHR, textStatus, errorThrown);
          }
          reject(errorThrown);
        },
        complete: function (jqXHR, textStatus) {
          let callbackComplete = $[options.callbackComplete];
          if (typeof callbackComplete === 'function') {
            callbackComplete(jqXHR, textStatus);
          }
        }
      });
    });
  };

  /**
   * Cc Helper - Handles Ajax call triggers.
   *
   * @type {jQuery.Function}
   *
   * @return {jQuery}
   *  The original jQuery object for chaining.
   *
   * @note To define the data:
   *  There are two possibilities:
   *    - Using a callback function:
   *      data-ajax-data="helloData"
   *      The function must be:
   *      $.helloData = function () {
   *        return {
   *          foo: 'bar',
   *          biz: 'fuz',
   *        };
   *      };
   *    - Using an object:
   *      data-ajax-data='{"foo": "bar", "biz": "fuz"}'
   *      Single and double quotes emplacement is very important.
   */
  $.ccHelperAjaxCallTriggers = function () {
    // For each.
    $('.cc-trigger-ajax').each(function () {
      // Handles click.
      $(this).on('click', function (e) {
        e.preventDefault();
        let $trigger = $(this);
        // Check if the call require a validation.
        let execute = true;
        if ($trigger.hasClass('cc-trigger-ajax-need-validation')) {
          // Find the closest form in the context of the Nav Pills.
          let $form = $trigger.closest('.cc-nav-pills').find('form');
          if ($form.hasClass('needs-validation')) {
            if (!$form.find('.tab-pane.active').ccHelperFormValidationPart()) {
              execute = false;
            }
          }
        }
        // Stop progression if needed.
        if (!execute) {
          return;
        }
        // If locking mechanism is required.
        let isLock = $trigger.data('lock');
        let isLocked = Boolean($trigger.hasClass('cc-locked'));
        if (isLock) {
          $trigger.addClass('cc-locked');
        }
        if (!isLocked) {
          // Start loading animation.
          if ($trigger.hasClass('btn')) {
            $trigger.addClass('cc-btn-loading');
          }
          // Set the Ajax call URL.
          let url = $trigger.attr('href') || '';
          if (url === '') {
            // Messages.
            let lines = [
              'Unable to perform the requested ajax call!',
              'The trigger does not define the URL to call, please fill the "href" element attribute.',
              'Trigger: "' + $trigger.text() + '":',
            ];
            console.error(lines.join('\n'), {
              'trigger': $trigger,
              'url': url,
            });
            // Return.
            return;
          }
          // Set the Ajax call options.
          let options = {
            async: $trigger.data('ajax-async') === undefined ? true : !!$trigger.data('ajax-async'),
            cache: $trigger.data('ajax-cache') === undefined ? true : !!$trigger.data('ajax-cache'),
            callbackBeforeSend: $trigger.data('ajax-callback-before') || null,
            callbackComplete: $trigger.data('ajax-callback-complete') || null,
            callbackOnError: $trigger.data('ajax-callback-on-error') || null,
            callbackOnSuccess: $trigger.data('ajax-callback-on-success') || null,
            data: $trigger.data('ajax-data') || null,
            dataType: $trigger.data('ajax-data-type') || 'json',
            headers: $trigger.data('ajax-headers') === undefined ? {} : JSON.parse($trigger.attr('data-ajax-headers')),
            method: $trigger.data('ajax-method') || 'post',
            timeout: $trigger.data('ajax-timeout') || null,
          };
          // Perform the Ajax call.
          $.ccHelperAjaxCall(url, options)
            .then(function (response) {
            })
            .catch(function (error) {
              // Messages.
              let lines = [
                'Unable to perform the requested ajax call!',
                'Trigger: "' + $trigger.text() + '":',
              ];
              console.error(lines.join('\n'), {
                'url': url,
                'options': options,
                'error': error
              });
            })
            .finally(function () {
              // Stop loading animation.
              $trigger.removeClass('cc-btn-loading');
              // If locking mechanism has been used.
              if ($trigger.hasClass('cc-locked')) {
                $trigger.removeClass('cc-locked');
              }
            });
        }
      });
    });
    // Return.
    return this;
  };

  /**
   * Cc Helper - Bootstrap popovers.
   *
   * @type {jQuery.Function}
   *
   * @return {jQuery}
   *  The original jQuery object for chaining.
   */
  $.ccHelperBsPopover = function () {
    // For each.
    $('[data-bs-toggle="popover"]').each(function () {
      new bootstrap.Popover($(this));
    });
    // Return.
    return this;
  };

  /**
   * Cc Helper - Bootstrap tooltips.
   *
   * @type {jQuery.Function}
   *
   * @return {jQuery}
   *  The original jQuery object for chaining.
   */
  $.ccHelperBsTooltip = function () {
    // For each.
    $('[data-bs-toggle="tooltip"]').each(function () {
      new bootstrap.Tooltip($(this));
    });
    // Return.
    return this;
  };

  /**
   * Cc Helper - Handles Ajax Clipboard triggers.
   *
   * @type {jQuery.Function}
   *
   * @return {jQuery}
   *  The original jQuery object for chaining.
   */
  $.ccHelperClipboardTriggers = function () {
    // For each.
    $('.cc-trigger-clipboard').each(function () {
      // Handles click.
      $(this).on('click', function (e) {
        e.preventDefault();
        let $trigger = $(this);
        // Get the value to add in the clipboard.
        let text = $('#' + $(this).data('for')).text();
        if ($.trim(text) !== '') {
          // Add to clipboard.
          navigator.clipboard.writeText(text).then(function () {
            // Check if the icon is the Bootstrap clipboard icon.
            if ($trigger.find('i.bi').hasClass('bi-clipboard')) {
              // Reference to the icon element.
              let $icon = $trigger.find('.bi-clipboard');
              // Instantly change the icon from clipboard to clipboard-check.
              $icon.removeClass('bi-clipboard').addClass('bi-clipboard-check');
              // Set a delay of 2 seconds to revert back to the original icon.
              setTimeout(function () {
                // Fade out the checked icon.
                $icon.animate({opacity: 0.5}, 'slow', function () {
                  // Revert back to the original clipboard icon.
                  $icon.removeClass('bi-clipboard-check').addClass('bi-clipboard');
                  // Fade in the original icon.
                  $icon.animate({opacity: 1}, 'slow');
                });
              }, 1000);
            }
          });
        }
      });
    });
    // Return.
    return this;
  };

  /**
   * Cc Helper - Get IPv4 address.
   * $.ccHelperIpGetIpv4().then(function(ip) {...}).catch(function(error) {console.error(error);});
   *
   * @type {jQuery.Function}
   *
   * @return {Promise<string>}
   */
  $.ccHelperIpGetIpv4 = function () {
    // Return.
    return new Promise(function (resolve, reject) {
      $.getJSON("https://api.ipify.org?format=jsonp&callback=?")
        .done(function (json) {
          resolve(json.ip);
        })
        .fail(function (jqxhr, textStatus, error) {
          reject(new Error("Request Failed: " + textStatus + ", " + error));
        });
    });
  };

  /**
   * Cc Helper - Get IPv6 address.
   * $.ccHelperIpGetIpv6().then(function(ip) {...}).catch(function(error) {console.error(error);});
   *
   * @type {jQuery.Function}
   *
   * @return {Promise<string>}
   */
  $.ccHelperIpGetIpv6 = function () {
    // Return.
    return new Promise(function (resolve, reject) {
      $.getJSON("https://api64.ipify.org?format=jsonp&callback=?")
        .done(function (json) {
          resolve(json.ip);
        })
        .fail(function (jqxhr, textStatus, error) {
          reject(new Error("Request Failed: " + textStatus + ", " + error));
        });
    });
  };

  /**
   * Cc Helper - Checks if a jQuery selector exists.
   *
   * @type {jQuery.Function}
   *
   * @param {jQuery.Object} callback
   *  The callback function, executed if the selector exists.
   *
   * @return {jQuery}
   *  The original jQuery object for chaining.
   */
  $.fn.ccHelperSelectorExists = function (callback) {
    const args = [].slice.call(arguments, 1);
    if (this.length) {
      callback.call(this, args);
    }
    // Return.
    return this;
  };

}(jQuery, window, document));
